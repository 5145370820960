<template>
    <div class="footer-wrap">
        <div class="footer-list">
            <div class="footer-list-left">
                <img src="../../assets/logo.png" alt="" />
                <div class="siteName">公司地址</div>
                <div class="site">河北省-邯郸市-丛台区滏东北大街555号</div>
            </div>
            <div class="footer-list-center">
                <div class="item">
                    <ul>
                        <li>关于我们</li>
                        <li @click="skip('intro')">团队简介</li>
                        <li @click="skip('culture')">企业文化</li>
                        <li @click="skip('course')">发展历程</li>
                    </ul>
                </div>
                <div class="item">
                    <ul>
                        <li>加入我们</li>
                        <li @click="skip('idea')">人才理念</li>
                        <li @click="skip('join')">加入我们</li>
                        <li @click="skip('cooperation')">商务合作</li>
                    </ul>
                </div>
                <div class="item">
                    <ul>
                        <li>联系我们</li>
                        <li>0310-7058999</li>
                        <li>contact@hididesign.com</li>
                    </ul>
                </div>
            </div>
            <div class="footer-list-right">
                <div class="img">
                    <div class="footer-img">
                        <span>关注我们</span>
                        <img src="../../assets/code.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <div class="foot">
                <span>13liangzao.com 版权所有</span>
                <span>邯郸一三良造科技有限公司</span>
                <span @click="open" class="open">冀ICP备2024060119号</span>
                <img class="beian" src="../../assets/beian.png" alt="">
                <span>冀公网安备13040302001631</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    mounted() {
    },
    data() {
        return {
            beian: '',
            copyright: '',
            app_qr: '',
            wechat: '',
            mini_program: '',
        }
    },
    methods: {
        skip(val) {
            this.$router.push(`/${val}`).catch(err => {})
        },
        open(){
            window.open('https://beian.miit.gov.cn/', '_blank')
        }
    },
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
}
.footer-wrap {
    width: 24rem;
    background: #2D312F;
    color: #ffffff;
    .footer-list {
        box-sizing: border-box;
        padding: 1.13rem 3.25rem 0rem 3.25rem;
        height: 4.15rem;
        display: flex;
        justify-content: space-between;
        .footer-list-left{
            img{
                width: 1.88rem;
                height: .44rem;
            }
            .siteName{
                font-weight: 400;
                font-size: .17rem;
                color: rgba(255,255,255,0.5);
                margin: .63rem 0rem .33rem;
            }
            .site{
                font-weight: 400;
                font-size: .2rem;
                color: #FFFFFF;
            }
        }
        .footer-list-center{
            width: 8.25rem;
            display: flex;
            justify-content: space-between;
            ul :first-child {
                font-weight: 500;
                font-size: .2rem;
                color: #FFFFFF;
            }
            ul {
                list-style: none;
                font-weight: 400;
                font-size: .17rem;
                color: rgba(255,255,255,0.5);
                flex-direction: column;
                li {
                    cursor: pointer;
                    font-size: 0.15rem;
                    font-weight: 400;
                    margin-bottom: .31rem;
                }
            }
        }
        .footer-list-right{
            .img{
                .footer-img{
                    display: flex;
                    flex-direction: column;
                    font-weight: 500;
                    font-size: .2rem;
                    color: #FFFFFF;
                    img{
                        margin-top: .3rem; 
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
            }
        }
    }
    .footer-copyright {
        height: .89rem;
        background: rgba(255,255,255,0.03);
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: .17rem;
        color: #FFFFFF;
        line-height: .89rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        .open{
            margin: 0rem .13rem;
            cursor: pointer;
            color: #3760a9;
        }
        .beian{
            margin: 0rem .06rem;
            width: .19rem;
            height: .19rem;
        }
        :nth-child(1) span{
            margin-right: .06rem;
        }
    }
}
</style>
