/*
 * @Author: zhaoyanpeng 2235576749@qq.com
 * @Date: 2023-09-06 13:14:05
 * @LastEditors: zhaoyanpeng 2235576749@qq.com
 * @LastEditTime: 2023-09-06 14:23:37
 * @FilePath: \web-front\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import "@/assets/font/font.css"
import '@/utils/flexible.js';

import App from './App'
// import store from './store'
import router from './router'
import BaiduMap from 'vue-baidu-map'

Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

Vue.use(BaiduMap, {
    /* 需要注册百度地图开发者来获取你的ak */ ak: '2dZDc4X5QMTnvp9w2CvEGSYhhnk7rraO',
})

new Vue({
    el: '#app',
    router,
    // store,
    render: h => h(App),
})
