<template>
    <div :class="!topVal ? 'header-wrap' : 'header-wrap head-active'">
        <div class="header-content">
            <div class="head-content-left">
                <div class="logo" @click="skipHome()">
                    <img src="../../assets/logo.png" alt="" v-if="!topVal"/>
                    <img src="../../assets/logoAct.png" alt="" v-else />
                </div>
                <ul class="list">
                    <li v-for="(item, index) in nav" :key="index" @mouseenter="showDropdown(index)" @mouseleave="leave" :class="item.path === navAct ? 'item item-act' : 'item'">
                        <a @click="skip(item)">{{ item.title }}</a>
                        <div v-if="item.child && activeIndex === index" class="item-box">
                            <div class="triangle-up"></div>
                            <ul class="li-item">
                                <li v-for="(dropdownItem, dropdownIndex) in item.child" :key="dropdownIndex">
                                    <a @click="skip(dropdownItem)">{{ dropdownItem.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- <div class="head-content-right">
                <div class="nav-right">
                    <span>关注我们</span>
                    <span>了解更多</span>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { createLogger } from 'vuex'
export default {
    name: 'Header',
    props: ['topVal'],
    data() {
        return {
            routeActive: '/home',
            activeIndex: -1,
            nav: [
                {
                    title: '交易平台',
                    path: '/jiaoyi',
                    child: [
                        {
                            name: '3D建模',
                            path: '/modeling',
                        },
                        {
                            name: '产品打样',
                            path: '/specimen',
                        },
                        {
                            name: '产品测试&表面处理',
                            path: '/productText',
                        },
                        {
                            name: '定制化服务',
                            path: '/customizedService',
                        },
                    ],
                },
                {
                    title: '数字化工厂',
                    path: '/shuzihua',
                    child: [
                        {
                            name: '商家管理',
                            path: '/merchantManagement',
                        },
                        {
                            name: '智能化工厂',
                            path: '/intelligentFactory',
                        },
                    ],
                },
                {
                    title: '产学研',
                    path: '/chanxueyan',
                    child: [
                        {
                            name: '科研与人才培养',
                            path: '/cultivate',
                        },
                        {
                            name: '知识产权和生产落地',
                            path: '/property',
                        },
                    ],
                },
                {
                    title: '平台服务',
                    path: '/pingtaifuwu',
                    child: [
                        {
                            name: '行业应用',
                            path: '/adhibition',
                        },
                        {
                            name: '金融服务',
                            path: '/financial',
                        },
                        {
                            name: '法律服务',
                            path: '/legislation',
                        },
                        {
                            name: '营销服务',
                            path: '/marketing',
                        },
                    ],
                },
            ],
            navAct: '/dealPlatform',
        }
    },
    methods: {
        // 下拉菜单
        showDropdown(index) {
            if (this.activeIndex === index) {
                this.activeIndex = -1
            } else {
                this.activeIndex = index
            }
        },
        leave() {
            this.activeIndex = -1
        },
        // 跳转
        skip(item) {
            if (
                item.path === '/jiaoyi' ||
                item.path === '/shuzihua' ||
                item.path === '/chanxueyan' ||
                item.path === '/pingtaifuwu'
            ) {
                return
            }
            if (
                item.path === '/modeling' ||
                item.path === '/specimen' ||
                item.path === '/productText' ||
                item.path === '/customizedService'
            ) {
                this.navAct = '/jiaoyi'
            } else if(
                item.path === '/merchantManagement' ||
                item.path === '/intelligentFactory'
            ) {
                this.navAct = '/shuzihua'
            } else if(
                item.path === '/cultivate' ||
                item.path === '/property'
            ) {
                this.navAct = '/chanxueyan'
            } else if(
                item.path === '/adhibition' ||
                item.path === '/financial'  ||
                item.path === '/legislation'  ||
                item.path === '/marketing'
            ) {
                this.navAct = '/pingtaifuwu'
            }
            this.$router.push({ path: item.path }).catch(err => {})
        },
        skipHome(){
            this.navAct = '/home'
            this.$router.push({ path: "/dealPlatform" }).catch(err => {})
        }
    },
    created() {
        this.skip(this.$route)
    },
    watch: {
        $route(to, from) {
            // 监听 $route 对象的变化
            // 可以执行相应的逻辑
            // 例如：根据路由的变化重新加载数据
            // console.log(to,"to");
            this.skip(to)
        },
    },
    
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
}
.header-wrap {
    transition: all 1.5s ease;
    // background: #000000;
    background-color: rgba(255,255,255,0.2);
    backdrop-filter: blur(5px);
    width: 24rem;
    z-index: 1000;
    position: fixed;
    border-bottom: 1px solid #FFFFFF;
    // border: 1px solid transparent;
    // border-color: rgba (255, 255, 255, 0.3) !important;
    .header-content {
        display: flex;
        justify-content: space-between;
        padding: 0rem 3.25rem;
        margin: 0 auto;
        height: 1.25rem;
        line-height: 1.25rem;
        font-weight: 400;
        font-size: .25rem;
        color: #FFFFFF;
        .head-content-left {
            display: flex;
            .logo{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: 1.38rem;
                img{
                    width: 2.5rem;
                    height: .59rem;
                }
            }
            .list {
                display: flex;
                list-style: none;
                .item {
                    .item-box {
                        width: 1.5rem;
                        height: 2.75rem;
                        .li-item{
                            font-weight: 500;
                            font-size: .23rem;
                            color: #222222;
                        }
                        .triangle-up {
                            position: absolute;
                            left: 40%;
                            top: 1.38rem;
                            width: 0;
                            height: 0;
                            transform: translateX(-50%);
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            border-left: .13rem solid transparent;  // 根据三角形方向选择对应的boder-direction
                            border-right: .13rem solid transparent; // 根据三角形方向选择对应的boder-direction
                            border-bottom: .13rem solid white; // 根据三角形方向选择对应的boder-direction
                        }
                    }
                    width: 1.25rem;
                    margin-right: .75rem;
                    height: 0.88rem;
                    position: relative;
                    display: block;
                    a {
                        cursor: pointer;
                    }
                    .li-item {
                        width: 2.38rem;
                        opacity: 1;
                        backdrop-filter: blur(0rem);
                        background-color: #fff;
                        // border-radius: 0.13rem;
                        z-index: 1000;
                        // padding: 0rem 0.13rem;
                        position: absolute;
                        top: 1.5rem;
                        left: -0.75rem;
                        text-align: center;
                        list-style: none;
                        line-height: 0.38rem;
                        font-weight: 400;
                        li {
                            // width: 2.38rem;
                            height: 0.63rem;
                            line-height: 0.63rem;
                            font-size: 0.17rem;
                            font-weight: 400;
                            color: #666666;
                            // box-sizing: content-box;
                        }
                        li:hover {
                            color: #09A966;
                            font-weight: 400;
                        }
                    }
                }
                .item-act {
                    color: #09A966 !important;
                }
                .item:last-child{
                    margin-right: 0;
                }
            }
        }
        .head-content-right {
            .nav-right{
                span{
                    margin-left: .75rem;
                }
            } 
        }
    }
}
.head-active{
    transition: all 1.5s ease;
    background: #FFFFFF;
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.03);
    .header-content{
        color: #333333;
    }
}
</style>
