/* Layout */
import Layout from "@/layout";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 公共路由
export const constantRoutes = [
    {
        path: "/",
        redirect: "/dealPlatform",
        component: Layout,
        children: [
            {
                path: '/dealPlatform',
                name: 'dealPlatform',
                meta: { refresh: true },
                component: resolve => require(["@/views/dealPlatform/index"], resolve),
                moduleName: '首页',
            },
            {
                path: 'modeling',
                name: 'modeling',
                component: () => import('@/views/dealPlatform/modeling'),
                moduleName: '3D建模',
            },
            {
                path: 'specimen',
                name: 'specimen',
                component: () => import('@/views/dealPlatform/specimen'),
                moduleName: '产品打样',
            },
            {
                path: 'productText',
                name: 'productText',
                component: () => import('@/views/dealPlatform/productText'),
                moduleName: '产品测试',
            },
            {
                path: 'customizedService',
                name: 'customizedService',
                component: () => import('@/views/dealPlatform/customizedService'),
                moduleName: '定制化服务',
            },
            {
                path: 'merchantManagement',
                name: 'merchantManagement',
                component: () => import('@/views/digitizationFactory/merchantManagement'),
                moduleName: '商家管理',
            },
            {
                path: 'intelligentFactory',
                name: 'intelligentFactory',
                component: () => import('@/views/digitizationFactory/intelligentFactory'),
                moduleName: '智能化工厂',
            },
            {
                path: 'cultivate',
                name: 'cultivate',
                component: () => import('@/views/research/cultivate'),
                moduleName: '科研与人才培养',
            },
            {
                path: 'property',
                name: 'property',
                component: () => import('@/views/research/property'),
                moduleName: '知识产权和生产落地',
            },
            {
                path: 'adhibition',
                name: 'adhibition',
                component: () => import('@/views/serve/adhibition'),
                moduleName: '行业应用',
            },
            {
                path: 'financial',
                name: 'financial',
                component: () => import('@/views/serve/financial'),
                moduleName: '金融服务',
            },
            {
                path: 'legislation',
                name: 'legislation',
                component: () => import('@/views/serve/legislation'),
                moduleName: '法律服务',
            },
            {
                path: 'marketing',
                name: 'marketing',
                component: () => import('@/views/serve/marketing'),
                moduleName: '营销服务',
            },
            {
                path: 'intro',
                name: 'intro',
                component: () => import('@/views/aboutUs/intro'),
                moduleName: '团队简介',
            },
            {
                path: 'culture',
                name: 'culture',
                component: () => import('@/views/aboutUs/culture'),
                moduleName: '企业文化',
            },
            {
                path: 'course',
                name: 'course',
                component: () => import('@/views/aboutUs/course'),
                moduleName: '发展历程',
            },
            {
                path: 'cooperation',
                name: 'cooperation',
                component: () => import('@/views/joinUs/cooperation'),
                moduleName: '商务合作',
            },
            {
                path: 'idea',
                name: 'idea',
                component: () => import('@/views/joinUs/idea'),
                moduleName: '人才理念',
            },
            {
                path: 'join',
                name: 'join',
                component: () => import('@/views/joinUs/join'),
                moduleName: '加入我们',
            },
            {
                path: 'jobDetail/:id',
                name: 'jobDetail',
                component: () => import('@/views/joinUs/jobDetail'),
                moduleName: '职位详情',
            },
        ],
    },
];

export default new Router({
    mode: "hash", 
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
});
