<template>
    <div class="app-wrapper">
        <app-header class="head" :topVal="navBarFixed"/>
        <app-main class="app-main" />
        <app-footer/>
    </div>
</template>

<script>
import { AppMain } from './components'
import header from '@/layout/components/header'
import footer from '@/layout/components/footer'
export default {
    name: 'Layout',
    components: {
        appHeader: header,
        appFooter: footer,
        AppMain,
    },
    data() {
        return {
            navBarFixed: false,
        }
    },
    methods: {
        scrolling() {
            // 滚动条距文档顶部的距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // console.log("header 滚动距离 ", scrollTop);
            if(scrollTop > 500){
                this.navBarFixed = true;
                this.style = {background: "#FFFFFF",boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.03)",color:"#333333"};
            }else{
                this.navBarFixed = false;
                this.style='';
            }
        },
        add(){
            // 监听页面滚动事件
            window.addEventListener("scroll", this.scrolling);
        },
        remove(){
            window.removeEventListener("scroll", this.scrolling);
        },
        cutAct(item){
            console.log(item,"路由信息");
            if (
                item.name === 'jobDetail' ||
                item.path === '/culture' ||
                item.path === '/intro' ||
                item.path === '/course'
            ) {
                this.navBarFixed = true;
                this.style = {background: "#FFFFFF",boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.03)",color:"#333333"};
                this.remove()
            } else {
                this.navBarFixed = false;
                this.style='';
                this.add()
            }
        }
    },
    mounted() {
        this.add();
    },
    beforeDestroy() {
        this.remove();
    },
    watch: {
        $route(to, from) {
            // 监听 $route 对象的变化
            // 可以执行相应的逻辑
            // 例如：根据路由的变化重新加载数据
            // console.log(to,"to");
            this.cutAct(to)
        },
    },
}
</script>

<style lang="scss" scoped>
*{
    padding: 0;
    margin: 0;
}
// @import '~@/styles/mixin.scss';
// @import '~@/styles/variables.scss';
.app-wrapper {
    // // flex: 1;
    width: 24rem;
    position: relative;
    // overflow-x: hidden;
    // min-height: 100vh;
    // display: flex;
    // flex-direction: column;
    // // min-width: 17.5rem;
    // overflow-x: auto;
    // margin: 0 auto;
    // .app-main {
    //     // flex-grow: 1;
    // }
}
</style>
